import React, {useState} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import axios from "../../components/api/httpclient";
import { navigate, Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [unknownAccount, setUnknownAccount] = useState(false);
  const [unknownAccountLanguage, setUnknownAccountLanguage] = useState(false);
  const intl = useIntl();

  const handleChange = (event) => {
    setEmail(event.target.value);
  }

  const isUnknownAccount = () =>  {
    if (unknownAccount === true) {
      return true;
    }
    return false;
  }

  const isUnknownAccountLanguage = () =>  {
    if (unknownAccountLanguage === true) {
      return true;
    }
    return false;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(email !== "") {
      setUnknownAccount(false)
      setUnknownAccountLanguage(false)
      const response = axios.post(`/forgot-password`,
        {
          email: email,
          lang: intl.locale
        }).then(function (response) {
          console.log(response);

          navigate('/sessions/password-reset-link',
            {
              state: {
                email: email
              }
            });
      }).catch((error) => {
        if (error.toString().includes("404")) {
          setUnknownAccount(true);
          console.log('Email not found')
        }
        if ((error.toString()).includes("400")) {
          setUnknownAccountLanguage(true);
          console.log('email address is associated with another language')
        }
      });

    }
  }
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.password-reset" })} />
      <h2 className="mb-4"><Link to="/sessions/login" className="link-back grey-border"><FormattedMessage id="generic.nav_buttons.back_button" /></Link></h2>
      <div className="white-container mb-5">
        <h1 className="with-border-top with-border-bottom text-uppercase"><FormattedMessage id="1_1_3_login_forgot_password.title" /></h1>
        <p className="mb-4"><FormattedMessage id="1_1_3_login_forgot_password.error" /></p>
        <Form>
          <div className="row">
            <div className="col-md-8">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.email" /></Form.Label><span className="icon-check ml-2 d-none"></span>
                <Form.Control type="email" value={email} onChange={(e) => handleChange(e)}/>
              </Form.Group>
              {isUnknownAccount() &&
              (
                <div className="col-md-12">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_10" })}</small>
                  </Form.Group>
                </div>
              )
              }
              {isUnknownAccountLanguage() &&
              (
                <div className="col-md-12">
                  <Form.Group>
                    <small className="form-text form-validate-error"><span>X</span>{intl.formatMessage({ id: "generic.forms.form_error_message.error_message_14" })}</small>
                  </Form.Group>
                </div>
              )
              }
              <div className="required-field">*<FormattedMessage id="generic.forms.form_mentions.required_fields" /></div>
            </div>
            <div className="col-md-4 pt-label">
              <button type="submit" className="button red w-100 mx-auto" onClick={(e) => handleSubmit(e)}><FormattedMessage id="generic.forms.form_buttons.submit" /></button>
            </div>
          </div>
        </Form>
      </div>
      <p className="text-center"><FormattedMessage id="generic.forms.form_mentions.not_a_member" /></p>
      <div className="mb-4">
        <Link to="/sessions/signup" className="button white mx-auto"><FormattedMessage id="generic.forms.form_buttons.signup" /></Link>
      </div>
    </Layout>
  )
}

export default PasswordReset
